import React from 'react';

import BlogListItem from './BlogListItem.js';
import blogStyles from './Blog.module.scss';

const Blog = () => {
  const { blogList } = blogStyles;

  const { postDate, postTitle, postTopic, excerpt } = {
    postDate: 'May 3',
    postTitle: 'My First Post Title Here To Fill Up Extra Space',
    postTopic: 'Programming',
    excerpt:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione nihil veniam quasi voluptas, facere error, eaque porro quis voluptate quia, unde ipsum eveniet reprehenderit molestias.',
  };

  return (
    <main className={blogList}>
      <BlogListItem
        postDate={postDate}
        postTitle={postTitle}
        postTopic={postTopic}
        excerpt={excerpt}
      />
    </main>
  );
};

export default Blog;
