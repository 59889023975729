import React from 'react';
import { Link } from 'gatsby';

import styles from './BlogListItem.module.scss';

const BlogListItem = ({ postDate, postTitle, postTopic, excerpt }) => {
  const {
    blogListItem,
    titleWrap,
    title,
    publishedDate,
    content,
    topic,
  } = styles;

  return (
    <article className={blogListItem}>
      <div className={titleWrap}>
        <h2 className={title}>
          <Link to="/blog/post">
            <span className={publishedDate}>{postDate}</span>
            {postTitle}
          </Link>
        </h2>
      </div>
      <div className={content}>
        <div className={topic}>{postTopic}</div>
        <Link to="/blog/post">
          <p>{excerpt}</p>
        </Link>
      </div>
    </article>
  );
};

export default BlogListItem;
