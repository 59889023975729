import React from 'react';

import Layout from '../../components/Layout';
import BlogLayout from '../../components/Layout/BlogLayout';
import Blog from '../../components/Blog';

const BlogPage = () => {
  return (
    <Layout>
      <BlogLayout>
        <Blog />
      </BlogLayout>
    </Layout>
  );
};

export default BlogPage;
